<div class="flex h-[36px] flex-row items-center justify-between bg-base-100">
    <div class="flex flex-row items-center">
        <div
            *ngIf="title !== undefined; else breadCrumbs"
            id="tool-bar-title"
            class="!m-0 overflow-hidden pl-2"
        >
            <h2 *ngIf="!editTitle; else titleInput" class="!m-0 !ml-1 truncate">
                {{ title }}
            </h2>
            <ng-template #titleInput>
                <sz-input
                    class="block w-[200px]"
                    [(ngModel)]="title"
                    (valueChange)="titleChange.emit($event)"
                    [small]="true"
                />
            </ng-template>
        </div>
        <ng-template #breadCrumbs>
            <!-- Use flex-row-reverse, so that the path will be scrolled to the end
             on mobile. -->
            <div
                class="flex flex-row-reverse items-center overflow-x-auto px-2 scrollbar-hide"
            >
                <ng-container
                    *ngFor="let breadCrumb of reversedPath; index as i"
                >
                    <mat-icon
                        *ngIf="i !== 0"
                        class="!min-w-[24px] font-bold text-primary-900 dark:text-primary-200"
                        svgIcon="chevron-right"
                    ></mat-icon>
                    <button
                        class="cursor-pointer whitespace-nowrap px-1 py-2 font-medium text-gray-700 outline-none dark:text-white"
                        (click)="
                            breadCrumbClick.emit(reversedPath.length - i - 1)
                        "
                    >
                        {{ breadCrumb }}
                    </button>
                </ng-container>
            </div>
        </ng-template>
        <!-- Space for custom content in the toolbar -->
        <ng-content></ng-content>
    </div>
    <div class="flex flex-row items-center">
        <div class="flex flex-row items-center gap-1.5 px-1.5">
            <sz-chip
                *ngFor="let chip of chips"
                [icon]="chip.icon"
                [text]="chip.text"
                [color]="chip.color"
                [class.cursor-pointer]="chip.callback !== null"
                (click)="chip.click()"
            />
        </div>
        <ng-container *ngFor="let button of buttons">
            <button
                *ngIf="!button.hidden"
                class="flex h-9 w-10 items-center justify-center outline-none focus:bg-gray-100 enabled:hover:bg-gray-100 disabled:opacity-25 dark:focus:bg-gray-700 dark:enabled:hover:bg-gray-700"
                [matTooltip]="
                    button.tooltips[button.tooltipPosition] | translate
                "
                [matMenuTriggerFor]="button.menuReference"
                [disabled]="button.disabled"
                [class.!text-sky-500]="button.active"
                (click)="button.click()"
            >
                <mat-icon
                    class="!mr-0"
                    [svgIcon]="button.icons[button.iconPosition]"
                ></mat-icon>
            </button>
        </ng-container>
    </div>
</div>
