import { Component, Input } from '@angular/core';

@Component({
    selector: 'sz-illustration',
    templateUrl: 'illustration.component.html',
})
export class IllustrationComponent {
    @Input() name;
    @Input() title;
    @Input() description;
}
