import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { ButtonModule } from '../buttons/buttons.module';
import { DirectivesModule } from '../directives/directives.module';
import { DisplayModule } from '../display/display.module';
import { ImagesModule } from '../images/images.module';
import { LayoutModule } from '../layout/layout.module';
import { ListLayoutComponent } from './list-layout/list-layout.component';
import { ListOverlayDetailsComponent } from './list-overlay-details/list-overlay-details.component';
import { ItemPreviewComponent } from './item-preview/item-preview.component';
import { SelectedOverviewComponent } from './selected-overview/selected-overview.component';
import { StatsOverviewComponent } from './stats-overview/stats-overview.component';

const components = [
    ListLayoutComponent,
    SelectedOverviewComponent,
    ItemPreviewComponent,
    ListOverlayDetailsComponent,
    StatsOverviewComponent,
];

@NgModule({
    imports: [
        AppMaterialModule,
        DirectivesModule,
        LayoutModule,
        ButtonModule,
        DisplayModule,
        ImagesModule,
    ],
    declarations: components,
    exports: components,
})
export class ListPageModule {}
