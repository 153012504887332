<div
    class="flex max-h-full min-h-[40px] flex-col overflow-clip rounded-xl bg-base-100"
>
    <sz-overlay-title-bar
        *ngIf="showTitle"
        [isCollapsed]="isCollapsed"
        [buttonType]="buttonType"
        (toggleCollapsed)="toggleCollapsed()"
    >
        <span class="grow text-start text-sm text-base-content opacity-75">
            {{ name | translate }}
        </span>
    </sz-overlay-title-bar>
    <ng-content *ngIf="!isCollapsed" select="[header]" />
    <div *ngIf="!isCollapsed" class="max-h-full overflow-auto p-2 pt-0">
        <ng-content />
    </div>
</div>
