<a
    class="flex items-center justify-center outline-none"
    [ngClass]="{
        'h-6 w-6 rounded-md': isSmall,
        'h-12 w-12 rounded-xl': !isSmall,
        'opacity-25 pointer-events-none': disabled,
        'cursor-pointer hover:bg-gray-700/20 focus:bg-gray-700/20 dark:hover:bg-gray-400/20 dark:focus:bg-gray-400/20':
            !disabled
    }"
    [routerLink]="link"
    [queryParams]="queryParams"
>
    <div [ngClass]="{ 'h-5 w-5': isSmall, 'h-8 w-8': !isSmall }">
        <div [ngClass]="iconColorClasses">
            <mat-icon [svgIcon]="icon" [inline]="true"></mat-icon>
        </div>
    </div>
</a>
