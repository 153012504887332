import { Component, Input, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { CustomerRole } from 'src/app/services/constants';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { colorClass } from 'src/app/shared/color';
import { hasRole } from 'src/app/shared/common';
import { ListOverlayDetail } from 'src/app/shared/models/preview';

@Component({
    selector: 'sz-list-overlay-details',
    templateUrl: 'list-overlay-details.component.html',
})
export class ListOverlayDetailsComponent {
    @Input() details: ListOverlayDetail[] = [];
    translate = inject(TranslateService);
    clipboard = inject(ClipboardService);
    snackBar = inject(SnackBarService);

    get filteredDetails() {
        return this.details.filter(
            (detail) =>
                hasRole(detail.role ?? CustomerRole.Client) &&
                detail.value != null,
        );
    }

    valueColorClass(detail: ListOverlayDetail) {
        return colorClass(detail.color);
    }

    async copy(value: string) {
        if (!value) {
            return;
        }
        this.clipboard.copy(this.translate.instant(value));
        this.snackBar.open('common.value_copied');
    }

    isString(value: unknown) {
        return typeof value === 'string';
    }
}
