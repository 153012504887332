import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonConfig } from 'src/app/shared/button';
import { Preview } from 'src/app/shared/models/preview';

@Component({
    selector: 'sz-item-preview',
    templateUrl: 'item-preview.component.html',
})
export class ItemPreviewComponent {
    @Input({ required: true }) preview: Preview;
    @Input() showDeleteButton = true;
    @Input() buttons: ButtonConfig[];
    @Output() clearSelection = new EventEmitter<void>();
    @Output() delete = new EventEmitter();

    router = inject(Router);

    goToDevice(deviceId: number) {
        this.router.navigate(['devices/' + deviceId]);
    }
}
