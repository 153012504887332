import { NgModule } from '@angular/core';
import { DirectivesModule } from './directives/directives.module';
import { LayoutModule } from './layout/layout.module';
import { ListPageModule } from './list-page/list-page.module';
import { ButtonModule } from './buttons/buttons.module';
import { DisplayModule } from './display/display.module';
import { FormModule } from './form/form.module';
import { ImagesModule } from './images/images.module';
import { MonacoEditorModule } from './monaco-editor/monaco-editor.module';
import { NgxColorsModule } from 'ngx-colors';
import { RouterModule } from '@angular/router';

const subModules = [
    ButtonModule,
    DirectivesModule,
    DisplayModule,
    FormModule,
    ImagesModule,
    LayoutModule,
    ListPageModule,
    MonacoEditorModule,
    NgxColorsModule,
    RouterModule,
];

@NgModule({ imports: subModules, exports: subModules })
export class CommonModule {}
