<div
    class="flex aspect-square min-w-[12rem] flex-col items-center justify-center gap-4 rounded-2xl bg-gray-50 p-4 dark:bg-gray-800 max-sm:w-[50vw] sm:h-[50vh]"
>
    <img
        class="w-1/2 min-w-[10rem] aspect-square"
        src="assets/img/illustrations/{{ name }}.svg"
    />
    <div class="flex flex-col items-center">
        <div class="text-center text-lg">{{ title | translate }}</div>
        <div class="text-center text-sm sm:text-base">
            {{ description | translate }}
        </div>
    </div>
    <ng-content></ng-content>
</div>
