import {
    Component,
    EventEmitter,
    Input,
    Output,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonSize } from '../icon-button/icon-button.component';
import { CommonColor } from 'src/app/shared/color';

@Component({
    selector: 'sz-toggle-button',
    templateUrl: 'toggle-button.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleButtonComponent),
            multi: true,
        },
    ],
})
export class ToggleButtonComponent implements ControlValueAccessor {
    private _value = false;

    @Input() disabled = false;
    @Input() size: ButtonSize = 'small';
    @Input() positiveIcon = 'check-circle';
    @Input() positiveColor: CommonColor = 'primary';
    @Input() negativeIcon = 'circle-outline';
    @Input() negativeColor: CommonColor = 'default';
    @Output() valueChange = new EventEmitter<boolean>();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange = (_: boolean) => null;
    onTouched = () => null;

    @Input() set value(v: boolean) {
        if (!this.disabled) {
            this.writeValue(v);
        }
    }
    get value() {
        return this._value;
    }

    writeValue(value) {
        this._value = value;
        this.onChange(this._value);
        if (value != null) {
            this.valueChange.emit(this._value);
        }
    }

    registerOnChange(fn: (value: boolean) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
}
