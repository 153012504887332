import {
    Component,
    DestroyRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { isMobile, pause } from 'src/app/shared/common';

interface Section {
    id: string;
    title: string;
    icon: string;
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sz-section-selector',
    templateUrl: './section-selector.component.html',
    styles: [':host { z-index: 100; }'],
})
export class SectionSelectorComponent implements OnInit {
    @Input() sections: Section[] = [];
    @Input() selectedIndex = 0;
    @Input() useFragmentNavigation = true;

    @Output() selectedIndexChange = new EventEmitter<number>();
    destroyRef = inject(DestroyRef);

    @HostListener('window:resize')
    onResize() {
        this.isMobile = isMobile();
    }

    public isMobile = false;
    public isMenuOpen = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    async ngOnInit() {
        this.isMobile = isMobile();
        this.route.fragment
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(async (frag) => {
                // wait for next micro task to avoid errors
                await pause();
                this.selectSection(
                    this.sections.findIndex((s) => s.id == frag),
                );
            });
    }

    /**
     * Handle a click on the section with the given index.
     */
    sectionClicked(index) {
        if (this.useFragmentNavigation) {
            const fragment = this.sections[index].id;
            this.router.navigate(['.'], { relativeTo: this.route, fragment });
        } else {
            this.selectSection(index);
        }
    }

    /**
     * Select the previous section.
     */
    previousSection() {
        this.sectionClicked(
            this.selectedIndex == 0
                ? this.sections.length - 1
                : this.selectedIndex - 1,
        );
    }

    /**
     * Select the next section.
     */
    nextSection() {
        this.sectionClicked(
            this.selectedIndex == this.sections.length - 1
                ? 0
                : this.selectedIndex + 1,
        );
    }

    /**
     * Select the section with the given index.
     */
    selectSection(index) {
        if (index < 0 || index >= this.sections.length) {
            return;
        }
        this.selectedIndex = index;
        this.selectedIndexChange.emit(index);
    }
}
