import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ButtonConfig } from 'src/app/shared/button';
import { ListOverlayDetail } from 'src/app/shared/models/preview';

@Component({
    selector: 'sz-selected-overview',
    templateUrl: 'selected-overview.component.html',
})
export class SelectedOverviewComponent implements OnChanges {
    @Input({ required: true }) count: number;
    @Input() showDeleteButton = true;
    @Input() buttons: ButtonConfig[];
    @Output() clearSelection = new EventEmitter<void>();
    @Output() delete = new EventEmitter();

    details: ListOverlayDetail[] = [];

    ngOnChanges(changes: SimpleChanges) {
        if (changes.count) {
            this.details = [
                {
                    name: 'common.count',
                    value: (this.count ?? 0).toString(),
                },
            ];
        }
    }
}
