import {
    DestroyRef,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// modified from https://blog.bitsrc.io/angular-maximizing-performance-with-the-intersection-observer-api-23d81312f178
@Directive({ selector: '[szIntersectionObserver]' })
export class IntersectionObserverDirective implements OnInit {
    @Input() intersectionDebounce = 0;
    @Input() intersectionRootMargin = '0px';
    @Input() intersectionRoot: HTMLElement;
    @Input() intersectionThreshold: number | number[];

    @Output() visibilityChange = new EventEmitter<boolean>();

    destroyRef = inject(DestroyRef);

    constructor(private element: ElementRef) {}

    ngOnInit() {
        const element = this.element.nativeElement;
        const config = {
            root: this.intersectionRoot,
            rootMargin: this.intersectionRootMargin,
            threshold: this.intersectionThreshold,
        };
        fromIntersectionObserver(element, config, this.intersectionDebounce)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((status) => this.visibilityChange.emit(status));
    }
}

export const fromIntersectionObserver = (
    element: HTMLElement,
    config: IntersectionObserverInit,
    debounce = 0,
) =>
    new Observable<boolean>((subscriber) => {
        const subject$ = new Subject<boolean>();
        subject$
            .pipe(debounceTime(debounce))
            .subscribe(async (isVisible) => subscriber.next(isVisible));
        const intersectionObserver = new IntersectionObserver(
            (entries) =>
                entries.forEach((entry) =>
                    subject$.next(isIntersecting(entry)),
                ),
            config,
        );
        intersectionObserver.observe(element);
        return {
            unsubscribe() {
                intersectionObserver.disconnect();
                subject$.unsubscribe();
            },
        };
    });

function isIntersecting(entry: IntersectionObserverEntry) {
    return entry.isIntersecting || entry.intersectionRatio > 0;
}
