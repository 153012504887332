import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToolBarButton } from './tool-bar-button.model';
import { ToolBarChip } from './tool-bar-chip.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sz-tool-bar',
    templateUrl: './tool-bar.component.html',
})
export class ToolBarComponent {
    @Input() title: string;
    @Input() breadCrumbPath: string[] = [];
    @Input() chips: ToolBarChip[];
    @Input() buttons: ToolBarButton[];
    @Input() hasTopBorder = true;
    @Input() hasBottomBorder = true;
    @Input() editTitle = false;
    @Output() titleChange = new EventEmitter<string>();
    @Output() breadCrumbClick = new EventEmitter<number>();

    // Reverse the path to use with `flex-row-reverse`.
    get reversedPath() {
        return [...this.breadCrumbPath].reverse();
    }
}
