export type CommonColor =
    | 'default'
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'pin'
    | 'success'
    | 'warn';

export const colorClass = (color?: CommonColor) => {
    switch (color ?? 'default') {
        case 'default':
            return 'text-gray-700 dark:text-gray-300';
        case 'primary':
            return 'text-primary-700 dark:text-primary-300';
        case 'secondary':
            return 'text-secondary';
        case 'accent':
            return 'text-accent';
        case 'pin':
            return 'text-[goldenrod]';
        case 'success':
            return 'text-green-700 dark:text-green-300';
        case 'warn':
            return 'text-red-500';
    }
};
