<button
    class="group flex w-full flex-row items-center gap-1 p-2 outline-none"
    (click)="toggleCollapsed.emit()"
>
    <ng-content />
    <div
        class="flex h-6 w-6 items-center justify-center rounded-md group-hover:bg-gray-700/20 group-focus:bg-gray-700/20 dark:group-hover:bg-gray-400/20 dark:group-focus:bg-gray-400/20"
    >
        <div class="h-5 w-5">
            <mat-icon
                *ngIf="buttonType === 'collapse'"
                [svgIcon]="isCollapsed ? 'chevron-down' : 'chevron-up'"
                [inline]="true"
            />
            <mat-icon
                *ngIf="buttonType === 'close' && !isCollapsed"
                svgIcon="close"
                [inline]="true"
            />
        </div>
    </div>
</button>
