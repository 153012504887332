<div class="h-content-no-tool-bar flex flex-row bg-base-200" #container>
    <div class="hidden min-w-[16rem] max-w-[16rem] flex-col gap-2 p-3 md:flex">
        <sz-multi-select
            #filterOverlay
            *ngFor="let filter of filtersArray"
            [name]="filter.name"
            [options]="filter.options"
            [selected]="filter.selected"
            [exclusive]="filter.exclusive ?? false"
            [hideSelectAll]="true"
            [style.flexShrink]="filter.noScroll ? 0 : 1"
            [style.overflow]="filter.noScroll ? 'visible' : 'clip'"
            [style.minHeight]="filterOverlay.isCollapsed ? '40px' : '120px'"
            (selectedChange)="
                filter.selected = $event; filterUpdate.emit(filter)
            "
        />
    </div>
    <div class="flex h-full grow flex-col md:max-lg:pe-3 md:max-lg:pt-3">
        <div
            class="flex flex-col gap-2 max-md:p-2 lg:hidden w-full max-w-[400px] ms-auto"
        >
            <ng-container *ngTemplateOutlet="primaryColumn"></ng-container>
        </div>
        <div
            class="flex grow flex-col items-center overflow-y-auto bg-base-100 md:my-3 md:rounded-xl"
        >
            <div
                class="grid grid-cols-1 gap-2 p-3 max-lg:w-full lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
            >
                <ng-content />
            </div>
            <!-- TODO: maybe someday we could make a fancier
                       loading indicator -->
            <div
                *ngIf="!allItemsRendered"
                szIntersectionObserver
                (visibilityChange)="loadVisibilityChanged($event)"
                class="animate-pulse p-3 text-lg text-base-content"
            >
                {{ 'loading' | translate }}
            </div>
        </div>
    </div>
    <div class="hidden min-w-[16rem] max-w-[16rem] flex-col gap-2 p-3 lg:flex">
        <ng-container *ngTemplateOutlet="primaryColumn"></ng-container>
    </div>
</div>
<ng-template #primaryColumn>
    @if (showNewButton) {
        <div class="flex justify-end">
            <button mat-flat-button color="primary" (click)="add.emit()">
                <mat-icon svgIcon="plus" class="btn-icon" />
                <span>{{ 'common.new' | translate }}</span>
            </button>
        </div>
    }
    @if (selectedIds.length == 0) {
        <sz-stats-overview
            [count]="count"
            [details]="overviewStats"
            [buttons]="overlayButtons"
        />
    } @else if (selectedIds.length == 1) {
        <sz-item-preview
            [preview]="preview"
            [selectedIds]="selectedIds"
            [showDeleteButton]="showDeleteButton"
            [buttons]="overlayButtons"
            (clearSelection)="clearSelection.emit()"
            (delete)="delete.emit()"
        />
    } @else {
        <sz-selected-overview
            [count]="selectedIds.length"
            [buttons]="overlayButtons"
            [showDeleteButton]="showDeleteButton"
            (clearSelection)="clearSelection.emit()"
            (delete)="delete.emit()"
        />
    }
</ng-template>
