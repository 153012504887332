import {
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { interval } from 'rxjs';
import { Preview } from 'src/app/shared/models/preview';
import { ListFilter } from '../common';
import { ButtonConfig } from 'src/app/shared/button';

@Component({
    selector: 'sz-list-layout',
    templateUrl: './list-layout.component.html',
})
export class ListLayoutComponent implements OnInit, OnChanges {
    @Input({ required: true }) filters: Record<string, ListFilter>;
    @Input({ required: true }) count: number;
    @Input({ required: true }) selectedIds: number[];
    @Input({ required: true }) preview: Preview;
    @Input({ required: true }) overlayButtons: ButtonConfig[];
    @Input({ required: true }) allItemsRendered = false;
    @Input() overviewStats = [];
    @Input() showNewButton = true;
    @Input() showDeleteButton = true;
    @Output() filterUpdate = new EventEmitter<ListFilter>();
    @Output() clearSelection = new EventEmitter<void>();
    @Output() add = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() needMore = new EventEmitter<void>();

    @ViewChild('container', { static: true }) container: ElementRef;
    destroyRef = inject(DestroyRef);
    isLoadingVisible = false;
    filtersArray: ListFilter[] = [];

    ngOnInit() {
        interval(1000)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                if (this.isLoadingVisible && !this.allItemsRendered) {
                    this.needMore.next();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filters) {
            this.filtersArray = Object.values(this.filters);
        }
    }

    loadVisibilityChanged(status: boolean) {
        this.isLoadingVisible = status;
        if (status) {
            this.needMore.emit();
        }
    }
}
