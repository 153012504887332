import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { ChipComponent } from './chip/chip.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ImagesModule } from '../images/images.module';

const components = [ChipComponent, ComingSoonComponent];

@NgModule({
    imports: [AppMaterialModule, ImagesModule],
    declarations: components,
    exports: components,
})
export class DisplayModule {}
