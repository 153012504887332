import {
    Component,
    EventEmitter,
    Input,
    Output,
    forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'sz-text-box',
    templateUrl: './text-box.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextBoxComponent),
            multi: true,
        },
    ],
})
export class TextBoxComponent {
    private _value = null;

    @Input() disabled = false;
    @Input() placeholder = '';
    @Input() required = false;
    @Input() rows = '2';
    @Input() set value(v) {
        if (!this.disabled) {
            this.writeValue(v);
        }
    }
    get value() {
        return this._value;
    }

    @Output() valueChange = new EventEmitter();

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    onChange = (value) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    writeValue(value) {
        this._value = value;
        this.onChange(this.value);
        if (value != null) {
            this.valueChange.emit(value);
        }
    }

    registerOnChange(fn: (value) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
}
