import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    Pipe,
    PipeTransform,
    ViewChild,
} from '@angular/core';
import { pause } from 'src/app/shared/common';

@Pipe({ name: 'searchOptions' })
export class SearchOptionsPipe implements PipeTransform {
    transform = (options: FilterOption[], filter: string) => {
        filter = filter.toLowerCase();
        const filtered = options.filter(
            ({ name, aliases }) =>
                name.toLowerCase().includes(filter) ||
                (aliases ?? []).some((alias) =>
                    alias.toLowerCase().includes(filter),
                ),
        );
        return filtered;
    };
}

export type MultiSelectOption = {
    name: string;
    value: number | string;
    disabled?: boolean;
};

export type FilterOption = MultiSelectOption & {
    aliases?: string[];
    count?: number;
};

export type ConversionConfig = {
    nameField?: string;
    includeOption?: (value: number) => boolean;
    aliasFields?: string[];
    keepOrder?: boolean;
};

/**
 * This function can be used by .map to map a list of items to filter options.
 */
export const convertToOptions = (
    rawOptions: { id: number }[],
    config?: ConversionConfig,
): FilterOption[] => {
    config ??= {};
    config.nameField ??= 'name';
    config.aliasFields ??= [];
    config.keepOrder ??= false;
    let options = rawOptions.map((item) => ({
        name: item[config.nameField] as string,
        aliases: Object.keys(item)
            .filter((f) => config.aliasFields.includes(f))
            .map((f) => `${item[f]}`),
        value: item.id,
    }));
    if (config.includeOption) {
        options = options.filter(({ value }) => config.includeOption(value));
    }
    if (!config.keepOrder) {
        options.sort((a, b) => a.name.localeCompare(b.name));
    }
    return options;
};

@Component({
    selector: 'sz-multi-select',
    templateUrl: 'multi-select.component.html',
})
export class MultiSelectComponent implements AfterViewInit, OnChanges {
    @Input({ required: true }) name: string;
    @Input({ required: true }) options: FilterOption[];
    @Input() hideSelectAll = false;
    @Input() showTitle = true;
    @Input() selected: (number | string)[] = [];
    @Input() exclusive = false;
    @Output() selectedChange = new EventEmitter<(number | string)[]>();
    isCollapsed = false;

    @ViewChild('toggleAllCheckbox', { static: true })
    toggleAllCheckbox: ElementRef;

    filter = '';

    get areAllOptionsDisabled() {
        return this.options.every((o) => o.disabled);
    }

    async ngAfterViewInit() {
        await pause(); // This helps us avoid an NG0100 error
        if (this.exclusive && this.selected.length != 1) {
            this.selected = [];
            // Select the first item by default.
            this.toggleOption(this.options[0]);
        }
    }

    ngOnChanges() {
        this.updateOverallState();
    }

    isSelected(value: number | string) {
        return this.selected.includes(value);
    }

    isChecked(value: number | string) {
        if (this.hideSelectAll && this.selected.length == this.options.length) {
            return false;
        } else {
            return this.isSelected(value);
        }
    }

    toggleAll() {
        const checkbox = this.toggleAllCheckbox.nativeElement;
        checkbox.indeterminate = false;
        if (checkbox.checked) {
            this.selected = this.options
                .filter((o) => !o.disabled)
                .map(({ value }) => value);
        } else {
            this.selected = [];
        }
        this.selectedChange.emit(this.selected);
    }

    toggleOption(option: FilterOption) {
        if (this.exclusive) {
            if (this.isSelected(option.value)) {
                // Do nothing, can't unselect a radio item
            } else {
                this.selected = [option.value];
            }
        } else {
            if (this.isSelected(option.value)) {
                this.unselectOption(option);
            } else {
                this.selected = [...this.selected, option.value];
            }
        }
        this.selectedChange.emit(this.selected);
        this.updateOverallState();
    }

    unselectOption(option: FilterOption) {
        if (this.hideSelectAll) {
            if (this.selected.length === this.options.length) {
                // If all options are selected, unselect them all
                // and add the selected option to the selection
                this.selected = [option.value];
            } else if (this.selected.length === 1) {
                // If there is only one option selected, select them all again
                this.selected = this.options.map(({ value }) => value);
            } else {
                // Otherwise, remove the selected option
                this.selected = this.selected.filter((s) => s !== option.value);
            }
        } else {
            this.selected = this.selected.filter((s) => s !== option.value);
        }
    }

    updateOverallState() {
        if (this.toggleAllCheckbox) {
            const count = this.selected.length;
            const noneSelected = count == 0;
            const allSelected = count == this.options.length;
            const checkbox = this.toggleAllCheckbox.nativeElement;
            checkbox.indeterminate = !noneSelected && !allSelected;
            checkbox.checked = allSelected;
        }
    }
}
