import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { CommonColor, colorClass } from 'src/app/shared/color';
import { isMobile } from 'src/app/shared/common';

export type ButtonSize = 'small' | 'large' | 'auto';

@Component({
    selector: 'sz-icon-button',
    templateUrl: 'icon-button.component.html',
    styles: [':host { display: block }'],
})
export class IconButtonComponent {
    @Input({ required: true }) icon: string;
    @Input({ required: true }) size: ButtonSize;
    @Input() color: CommonColor = 'default';
    @Input() link: string;
    @Input() queryParams: Record<string, string>;
    @Input() disabled = false;

    isMobile = isMobile();

    @HostBinding('style.pointer-events') get pointerEvents(): string {
        return this.disabled ? 'none' : 'auto';
    }

    @HostListener('window:resize')
    onResize() {
        this.isMobile = isMobile();
    }

    get iconColorClasses() {
        return colorClass(this.color);
    }

    get isSmall() {
        switch (this.size) {
            case 'small':
                return true;
            case 'large':
                return false;
            case 'auto':
                return !this.isMobile;
        }
    }
}
