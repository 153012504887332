<button
    class="flex w-full h-full flex-col items-stretch gap-2 overflow-clip rounded-xl bg-base-200 p-2 outline-none hover:bg-base-300 focus:bg-base-300"
    [ngClass]="'status-' + status"
>
    <div class="flex flex-row gap-1">
        <div class="grow truncate text-start flex flex-col justify-center">
            <ng-content />
        </div>
        <div class="flex gap-1">
            <ng-content select="[actions]" />
        </div>
    </div>
</button>
