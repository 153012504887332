<ng-container [ngSwitch]="type">
    <div
        *ngSwitchCase="'default'"
        id="card-root"
        class="flex w-full flex-col items-stretch gap-2 overflow-clip rounded-xl bg-base-200 p-2"
    >
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <button
        *ngSwitchCase="'button'"
        id="card-root"
        class="flex w-full flex-col items-stretch gap-2 overflow-clip rounded-xl bg-base-200 p-2 outline-none hover:bg-base-300 focus:bg-base-300"
    >
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
</ng-container>
<ng-template #content>
    <div class="flex flex-row">
        <div class="grow truncate text-start">
            <ng-content select="[title]" />
        </div>
        <div class="flex gap-1">
            <ng-content select="[actions]" />
        </div>
    </div>
    <ng-content />
</ng-template>
