@defer (on idle) {
    <img
        [src]="imageSrc"
        [alt]="alt | translate"
        [ngClass]="classList"
        class="h-full"
    />
} @placeholder (minimum 500ms) {
    <div class="h-full rounded-full bg-base-300 animate-pulse"></div>
}
