import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { MonacoEditorComponent } from './monaco-editor.component';

@NgModule({
    imports: [AppMaterialModule],
    declarations: [MonacoEditorComponent],
    exports: [MonacoEditorComponent],
})
export class MonacoEditorModule {}
