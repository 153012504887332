import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { DialogButtonBarComponent } from './dialog-button-bar/dialog-button-bar.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [AppMaterialModule, RouterModule],
    declarations: [
        ToggleButtonComponent,
        IconButtonComponent,
        DialogButtonBarComponent,
    ],
    exports: [
        ToggleButtonComponent,
        IconButtonComponent,
        DialogButtonBarComponent,
    ],
})
export class ButtonModule {}
